<script setup>
  import { ref } from "vue";
  import CartAsidePricing from "../Cart/CartAsidePricing.vue";
  import CartAsideItems from "../Cart/CartAsideItems.vue";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const cartAsideTitleText = ref(cartAsideTitleTextCode);
  const cartAsideEditLinkText = ref(cartAsideEditLinkTextCode);
</script>

<template>
  <aside class="cart__aside">
    <!-- TO DO HARDCODED TEXT -->
    <span class="cart__aside-title">{{ cartAsideTitleText }}</span> <!-- VUE_CART_ASIDE_TITLE -->
    <div class="cart__aside-content">
      <CartAsideItems />
      <CartAsidePricing />

      <div class="cart__aside-links">
        <!-- TO DO HARDCODED -->
        <a :href="appendLanguagePrefixURL('/winkelwagen')" class="btn-text--small--primary-600">{{ cartAsideEditLinkText }}</a> <!-- VUE_CART_ASIDE_LINK_EDIT -->
      </div>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.cart__aside-title{
    @apply text-[17px] tracking-[.34px] font-fontText font-bold text-black block pb-3.5;
    @apply md:tracking-normal md:pb-2.5;
}

.cart__aside-content {
  @apply md:border-t md:border-solid md:border-border;
}

.cart__aside-links{
  @apply px-9 pb-6;
  @apply md:px-0;
}

.cart__aside {
  @apply col-span-4 col-start-9;
  @apply md:hidden;
}
</style>
