<script setup>
    import { ref, watch } from "vue";

    const props = defineProps({
        amountOfItems: {
            type: Number,
            default: 1
        },
        isCart: {
            type: Boolean,
            default: false
        },
    });

    const quantity = ref(props.amountOfItems);

    const decreaseQuantity = () => {
        if (quantity.value > 1) {
            quantity.value -= 1;
        }
    };

    const increaseQuantity = () => {
      quantity.value += 1;
    };

    watch(() => quantity.value, (newQuantityValue) => {
      if (newQuantityValue <= 0) {
        quantity.value = 1;
      }
    });

    const emit = defineEmits(['updatedQuantity']);
    watch(quantity, (newQuantity) => {
        emit('updatedQuantity', newQuantity);
    });
</script>

<template>
  <div class="product-quantity" :class="{'product-quantity--cart': isCart}">
    <div class="product-quantity__button product-quantity__minus" @click="decreaseQuantity">
      <i class="fa-solid fa-minus"></i>
    </div>
    <div class="product-quantity__input">
      <input type="number" v-model="quantity" min="1">
    </div>
    <div class="product-quantity__button product-quantity__plus" @click="increaseQuantity">
      <i class="fa-solid fa-plus"></i>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .product-quantity {
    @apply flex;
  }

  .product-quantity__button {
    @apply inline-flex items-center justify-center w-[40px] flex-[0_0_40px] border border-border text-14_21_12_21 transition-all duration-300 cursor-pointer;
    @apply hover:bg-[#F2F2F0] hover:border-[#F2F2F0];
    @apply sm:w-[38px] sm:h-[38px] sm:flex-[0_0_38px];

    &.product-quantity__minus {
      @apply rounded-l;
    }

    &.product-quantity__plus {
      @apply rounded-r;
    }
  }

  .product-quantity__input {
    @apply inline-flex w-[56px] h-[54px] flex-[0_0_56px] text-16_28_14_22 border-t border-b justify-center border-border;
    @apply sm:h-[38px] sm:w-[38px] sm:flex-[0_0_38px];

    input {
      @apply w-full text-center outline-none text-17_22_14_22;

      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        @apply m-0 appearance-none;
      }
    }
  }

  .product-quantity--cart {
    .product-quantity__button {
        @apply h-[44px] w-[44px] flex-[0_0_44px];
        @apply sm:h-[40px] sm:w-[40px] sm:flex-[0_0_40px];
    }

    .product-quantity__input {
        @apply h-[44px];
        @apply sm:h-[40px] sm:w-[40px] sm:flex-[0_0_40px];
    }
  }
</style>
