<script setup>
import { ref } from 'vue';
import { useDialog } from "../../../composables/useDialog";
import RBMImage from "../RBMImage.vue";
import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

const props = defineProps({
  data: {},
});

const productAddedToCartText = ref(productAddedToCartTextCode);
const checkoutButtonText = ref(checkoutButtonTextCode);
const continueShoppingText = ref(continueShoppingTextCode);
const relatedProductsTitleText = ref(relatedProductsTitleTextCode);
</script>

<template>
    <div class="dialog__content">
      <div class="dialog__header">
        <div class="dialog__header--content">
          <span class="dialog__title">{{ productAddedToCartText }}</span> <!-- VUE_PA_TITLE -->
          <div class="dialog__header--product">
            <i class="fa-solid fa-circle-check"></i>
            <figure class="asp-base dialog__header--product-image" v-if="data.product">
              <RBMImage :image="data.product.images[0]" />
            </figure>
            <p v-if="data.product" class="notranslate">{{data.product.title}}</p>
          </div>
        </div>
        <div class="dialog__header--buttons">
          <a :href="appendLanguagePrefixURL('/afrekenen')" class="btn-solid--primary-600">{{ checkoutButtonText }}</a> <!-- VUE_PA_CHECKOUT -->
          <p class="dialog__header--continue">of <a class="close-button" @click="useDialog().toggle()">{{ continueShoppingText }}</a></p>  <!-- VUE_PA_CONTINUE_SHOPPING -->
        </div>
      </div>
      <div class="dialog__products" v-if="data.product.related && data.product.related.length > 0">
        <p class="dialog__products--title">{{ relatedProductsTitleText }}</p> <!-- VUE_PA_RELATED_TITLE -->
        <ProductCard :isPopular="false" v-for="product in data.product.related" :product="product" :showWishlist="false" :show-skeleton=false />
      </div>
      <div class="dialog__buttons">
        <button class="btn-transp--border" @click="useDialog().toggle()">{{ continueShoppingText }}</button> <!-- VUE_PA_CONTINUE_SHOPPING -->
        <a :href="appendLanguagePrefixURL('/afrekenen')" class="btn-solid--primary-600">{{ checkoutButtonText }}</a> <!-- VUE_PA_CHECKOUT -->
      </div>
    </div>
</template>

<style lang="scss" scoped>
dialog {
  &[open] {
    @apply container grid grid-cols-12 gap-6 bg-transparent h-[90vh] p-0;
    @apply lg:px-6;
    @apply sm:px-4;
    @apply xs:p-0 xs:m-0 xs:max-w-full xs:h-full xs:max-h-full;

    &:focus-within {
      @apply outline-none;
    }

    &::backdrop {
      @apply bg-black/60;
      backdrop-filter: blur(2px);
    }
  }
}

.p-dialog-mask {
  .p-dialog {
    .p-dialog-content { 
      @apply min-w-[800px];
      @apply md:min-w-0;
    }
  }
}

.dialog__content {
  @apply col-span-8 col-start-3 h-full overflow-y-auto bg-white overscroll-contain;
  @apply mob:col-span-12;
}

.dialog__header {
  @apply grid grid-cols-12 gap-x-[28px] bg-lightGold-50 p-9;
  @apply sm:pt-[18px] sm:pb-[18px] sm:gap-x-[unset];

  .dialog__header--content {
    @apply col-span-8;
    @apply lg:col-span-7;
    @apply md:col-span-12;
  }

  .dialog__header--buttons {
    @apply col-span-4 flex flex-col;
    @apply lg:col-span-5;
    @apply md:col-span-12;
  }

  .btn-solid--primary-600, .dialog__header--continue {
    @apply col-span-4;
  }

  .dialog__header--continue {
    @apply col-start-9 text-center mt-[18px];
    @apply md:text-left;

    .close-button {
      @apply cursor-pointer lowercase;
    }
  }

  .btn-solid--primary-600 {
    @apply row-start-1;
  }
}

.dialog__title {
  @apply col-span-8 row-start-1 font-fontText font-bold text-20_28_15_21 text-dark;
}

.dialog__header--product {
  @apply col-span-8 flex items-center gap-x-[18px] relative;
  p {
    @apply text-16_24_14_18;
  }

  i {
    @apply absolute inset-0 top-[25%] text-green;
  }
}

.dialog__header--product-image {
  @apply w-16 h-16;
}

.dialog__products--title {
  @apply col-span-12 font-fontText font-bold text-20_28_15_21 text-dark;
}

.dialog__products {
  @apply grid grid-cols-12 gap-x-9 p-9 pt-6 border-b border-border max-w-[800px];
  @apply lg:px-6;
  @apply sm:px-4 sm:gap-x-[18px];

  .product__item {
    @apply col-span-4;
    @apply md:col-span-6;
  }

  .product__item-content {
    @apply flex h-full flex-col;
  }
}

.dialog__buttons {
  @apply p-9 pt-6 grid grid-cols-12 gap-x-3;
  a, button {
    @apply col-span-6;
  }
}


</style>
