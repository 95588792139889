import { createApp, markRaw } from "vue";
import '@/mixins/fetch';
import Cart from "./components/Cart";
import Categories from "./components/Category/Categories";
import ProductCard from "./components/ProductCard";
import CategoryHome from "./components/Category/CategoryHome";
import ProductDetail from "./views/ProductDetail";
import HighlightedBrands from "./components/HighlightedBrands";
import PopularProducts from "./components/PopularProducts/PopularProducts";
import Wishlist from "./components/Wishlist";
import ProductsView from "./components/Products/ProductsView";
import SearchAutocomplete from "./components/Utility/SearchAutocomplete";
import WishlistView from "./views/WishlistView";
import BreadCrumb from "./components/Utility/BreadCrumb";
import CustomDialog from "./components/Utility/CustomDialog.vue";
import MenuUser from "./components/MenuUser";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import RecaptchaField from "./components/Utility/RecaptchaField.vue"
// Checkout components
import CheckoutWrapper from './layouts/Checkout/CheckoutWrapper';
import CheckoutSteps from "./layouts/Checkout/CheckoutSteps";
import CheckoutPayment from "./layouts/Checkout/CheckoutPayment";

// Cart components
import CartWrapper from "./layouts/Cart/CartWrapper";

// Dashboard components
import DashboardRoot from "./profile/DashboardRoot.vue";

// Quotation components
import QuotationForm from "./views/QuotationForm";

// import Pinia
import pinia from "./includes/pinia";

// import PrimeVue
import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';

// import VueRouter
import router from "./includes/router/routes";
import ProductsRouteWrapper from "./components/Products/ProductsRouteWrapper.vue";
pinia.use(({ store }) => { store.router = markRaw(router) });

// Main Vue instance
const app = createApp({});

//register components
app
    .component("Cart", Cart)
    .component("CategoryHome", CategoryHome)
    .component("Categories", Categories)
    .component("ProductsRouteWrapper", ProductsRouteWrapper)
    .component("ProductsView", ProductsView)
    .component("ProductCard", ProductCard)
    .component("ProductDetail", ProductDetail)
    .component("PopularProducts", PopularProducts)
    .component("HighlightedBrands", HighlightedBrands)
    .component("Wishlist", Wishlist)
    .component("CheckoutSteps", CheckoutSteps)
    .component("CheckoutWrapper", CheckoutWrapper)
    .component("CheckoutPayment", CheckoutPayment)
    .component("BreadCrumb", BreadCrumb)
    .component("CartWrapper", CartWrapper)
    .component("SearchAutocomplete", SearchAutocomplete)
    .component("WishlistView", WishlistView)
    .component("CustomDialog", CustomDialog)
    .component("MenuUser", MenuUser)
    .component("DashboardRoot", DashboardRoot)
    .component("QuotationForm", QuotationForm)
		.component("RecaptchaField", RecaptchaField)
    .use(PrimeVue, {
        // unstyled: true
    })
    .use(router)
    .use(pinia)
		.use(VueReCaptcha, { siteKey: process.env.RECAPTCHA_KEY, loaderOptions: { autoHideBadge: true }})
    .directive('tooltip', Tooltip)
    .mount("#app");
